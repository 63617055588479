// extracted by mini-css-extract-plugin
export var __wab_slot = "PlasmicCheckbox-module--__wab_slot--FPlRH";
export var __wab_slotStringWrapper = "PlasmicCheckbox-module--__wab_slot-string-wrapper--K+2SK";
export var __wab_text = "PlasmicCheckbox-module--__wab_text--1euF-";
export var freeBox = "PlasmicCheckbox-module--freeBox--kjR07";
export var freeBox___focusVisibleWithin = "PlasmicCheckbox-module--freeBox___focusVisibleWithin--wGhcS";
export var labelContainer = "PlasmicCheckbox-module--labelContainer--cdGVV";
export var labelContainer___focusVisibleWithin = "PlasmicCheckbox-module--labelContainer___focusVisibleWithin--Z+k1Z";
export var root = "PlasmicCheckbox-module--root--EnkZt";
export var root___focusVisibleWithin = "PlasmicCheckbox-module--root___focusVisibleWithin--kz+FQ";
export var rootisChecked = "PlasmicCheckbox-module--rootisChecked--wvSg+";
export var rootisDisabled = "PlasmicCheckbox-module--rootisDisabled--5KrY9";
export var rootisIndeterminate = "PlasmicCheckbox-module--rootisIndeterminate--KShrY";
export var slotTargetChildren = "PlasmicCheckbox-module--slotTargetChildren--cNqX5";
export var slotTargetChildren___focusVisibleWithin = "PlasmicCheckbox-module--slotTargetChildren___focusVisibleWithin--tn-Fk";
export var svg = "PlasmicCheckbox-module--svg--MlOON";
export var svg___focusVisibleWithin = "PlasmicCheckbox-module--svg___focusVisibleWithin--r4Uh1";
export var svgisChecked = "PlasmicCheckbox-module--svgisChecked--iT6IO";
export var svgisDisabled = "PlasmicCheckbox-module--svgisDisabled--oQRlg";
export var svgisIndeterminate = "PlasmicCheckbox-module--svgisIndeterminate--x92uS";