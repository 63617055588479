// extracted by mini-css-extract-plugin
export var button = "PlasmicFormDemoRequest-module--button--o7Dmh";
export var checkbox__eWlnA = "PlasmicFormDemoRequest-module--checkbox__eWlnA--Thio7";
export var checkbox__fXfZo = "PlasmicFormDemoRequest-module--checkbox__fXfZo--k4Na5";
export var freeBox = "PlasmicFormDemoRequest-module--freeBox--iREmU";
export var link = "PlasmicFormDemoRequest-module--link--ucJcP";
export var root = "PlasmicFormDemoRequest-module--root--A62st";
export var svg___0Yrhc = "PlasmicFormDemoRequest-module--svg___0Yrhc--KIRQc";
export var svg__cCXbW = "PlasmicFormDemoRequest-module--svg__cCXbW--pSO4K";
export var svg__hRn2 = "PlasmicFormDemoRequest-module--svg__hRn2--43KyW";
export var svg__jVCi = "PlasmicFormDemoRequest-module--svg__jVCi--K14nE";
export var svg__klFUu = "PlasmicFormDemoRequest-module--svg__klFUu--kfQp1";
export var svg__m8Ken = "PlasmicFormDemoRequest-module--svg__m8Ken--MG1Nx";
export var svg__n7Php = "PlasmicFormDemoRequest-module--svg__n7Php--cwcOP";
export var svg__oZccu = "PlasmicFormDemoRequest-module--svg__oZccu--89kkY";
export var textInput__e1D5 = "PlasmicFormDemoRequest-module--textInput__e1D5--EbVmR";
export var textInput__tJz4 = "PlasmicFormDemoRequest-module--textInput__tJz4--qsY7h";
export var textInput__yt4HI = "PlasmicFormDemoRequest-module--textInput__yt4HI--ulDxs";