import axios from "axios"

const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

const handleSubmit = async () => {
  const fname = document.getElementById("name-input").value
  const lname = document.getElementById("surname-input").value
  const email = document.getElementById("email-input").value
  const privacyCheck = [
    ...document.getElementById("privacy-checkbox").classList,
  ].some(c => c.includes("rootisChecked"))

  /*
      map(c => c.toString())
    .some(c => c.contains("a"))
  */

  const marketingCheck = [
    ...document.getElementById("marketing-checkbox").classList,
  ].some(c => c.includes("rootisChecked"))

  if (fname.length === 0) {
    alert("Nome obbligatorio")
    return
  }
  if (lname.length === 0) {
    alert("Cognome obbligatorio")
    return
  }
  if (email.length === 0) {
    alert("Email obbligatoria")
    return
  }

  if (!validateEmail(email)) {
    alert("Email non valida")
    return
  }

  if (!privacyCheck) {
    alert("È obbligatorio accettare la normativa privacy")
    return
  }

  axios
    .post(
      "https://europe-west1-consiglio-io.cloudfunctions.net/consiglio-io-bussiness-contact",
      {
        email,
        fname,
        lname,
        marketingCheck,
        privacyCheck,
      }
    )
    .then(() => {
      window.location.href = "/grazie"
    })
    .catch(e => {
      console.error(e)
      alert(
        "Errore durante il salvataggio dei dati, assicurati di aver inserito dei dati validi"
      )
    })
}

const handleDemoRequestButton = () => {
  const buttons = document.getElementsByTagName("button")

  for (let i = 0; i < buttons.length; i++) {
    buttons[i].addEventListener("click", event => {
      if (event.target.textContent === "Invia richiesta") {
        handleSubmit()
      }
    })
  }
}

export default handleDemoRequestButton
